import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  signal,
  computed,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject, Subject, fromEvent } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { EmailLinkType, User } from 'src/models';
import { SidenavDrawerService } from './core/sidenav-drawer.service';
import { UserService } from './core/user.service';
import { RouterNavigationService } from './core/router-navigation.service';

const REGISTER_URL = 'type=register';
const RESET_PASSWORD_URL = 'password-reset';

/**
 * The main component loaded for the app.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  /** The sidenav displayed on mobile. */
  @ViewChild('sidenav', { static: true })
  sidenav!: MatSidenav;

  /** The component for the drawer. */
  @ViewChild('rightDrawer', { static: true })
  rightDrawer!: MatDrawer;

  /** Destroyed. */
  private destroyed$ = new Subject<void>();

  /** User data to hide/show admin menu. */
  private user$: ReplaySubject<unknown> = new ReplaySubject(1);

  /** The sequence of keys pressed by the user. */
  private sequence: string[];

  /** The code to activate dark mode. */
  private kunamiCode: string[];

  /** Signal with the current url. */
  private url = signal(this.router.url);

  /** Check if the current url is the map or not. */
  isMap = computed(() => this.url().includes('/map'));

  /** The previous drawer component. */
  previousDrawerComponent?: MatDrawer;

  /** Used to show top nav. */
  showTopNav = false;

  /** Used to show sidenav. */
  showSideNav = true;

  /** Dark mode enabled. */
  darkEnabled = false;

  /** User login. */
  userLogged = false;

  /** If a dialog is open. */
  isDialogOpen = false;

  constructor(
    public sidenavDrawerService: SidenavDrawerService,
    public userService: UserService,
    private router: Router,
    private navigation: RouterNavigationService,
    private cd: ChangeDetectorRef,
  ) {
    this.sequence = [];
    this.kunamiCode = [
      'arrowup',
      'arrowup',
      'arrowdown',
      'arrowdown',
      'arrowleft',
      'arrowright',
      'arrowleft',
      'arrowright',
      'b',
      'a',
    ];
    this.windowResize();
    this.handleKeyboardEvent();
  }

  /**
   * Subscribe to the dialog open event.
   */
  subscribeIsDialogOpen(): void {
    this.sidenavDrawerService.isDialogOpen$
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((isOpen) => {
        this.isDialogOpen = isOpen;
        if (this.isDialogOpen) {
          this.previousDrawerComponent =
            this.sidenavDrawerService.getDrawerComponent;
          this.sidenavDrawerService.getDrawerComponent &&
            this.sidenavDrawerService.closeDrawer();
          this.sidenavDrawerService.setDrawer(this.rightDrawer);
        } else if (this.previousDrawerComponent) {
          this.sidenavDrawerService.setDrawer(this.previousDrawerComponent);
        }

        this.cd.detectChanges();
      });
  }

  /**
   * Check the URL path and show/hide the navigation.
   */
  ngOnInit(): void {
    this.subscribeIsDialogOpen();
    this.userLogged = !!this.userService.user().rithmId || false;
    this.userService.setUserData();
    this.userService.userData$.pipe(takeUntil(this.user$)).subscribe((user) => {
      const info = user as User;
      this.userLogged = !!info?.rithmId;
    });

    this.sidenavDrawerService.setSidenav(this.sidenav);
    this.router.events
      .pipe(takeUntil(this.destroyed$))
      .subscribe((routerEvent) => {
        if (routerEvent instanceof NavigationEnd) {
          const path = routerEvent.url;
          const closeNav =
            path !== '' &&
            path !== '/' &&
            path !== '/forgot-password' &&
            path !== '/account-create' &&
            path !== '/password-reset' &&
            path?.toLowerCase().indexOf(REGISTER_URL) === -1 &&
            path?.toLowerCase().indexOf(RESET_PASSWORD_URL) === -1 &&
            path?.toLowerCase().indexOf('type=reset') === -1 &&
            path?.toLowerCase().indexOf('type=invite') === -1;

          this.showTopNav = closeNav;
          this.showSideNav = closeNav;
          this.url.set(path);
        }
      });
    this.validateInvitationExternalLink(window.location.href);
    this.navigation.startSaveRouterHistory$();

    //Sets height using a css variable. this allows us to avoid using vh. Mobile friendly.
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--appvh', `${vh}px`);
  }

  /**
   * Method for detect type and invitation external link in app.
   * @param url Link with Parameters.
   */
  validateInvitationExternalLink(url: string): void {
    const paramsLink = new URLSearchParams(url.split('?')[1]);
    if (
      paramsLink.get('type') &&
      paramsLink.get('type') === EmailLinkType.Invite &&
      paramsLink.get('guid')
    ) {
      this.showTopNav = false;
      this.showSideNav = false;
      this.userService.signOut(true, paramsLink);
    }
  }

  /**
   * Needed to resize a mobile browser when a the scrollbar hides.
   */
  windowResize(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        //Sets height using a css variable. this allows us to avoid using vh. Mobile friendly.
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--appvh', `${vh}px`);
      });
  }

  /**
   * Handle key presses from the user to check against the secret code.
   */
  handleKeyboardEvent(): void {
    /*fromEvent(window, 'keydown')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((e) => {
        const ev = e as KeyboardEvent;
        if (ev.key) {
          this.sequence.push(ev.key.toLowerCase());

          if (this.sequence.length > this.kunamiCode.length) {
            this.sequence.shift();
          }

          if (this.isSecretCode()) {
            this.addDarkMode();
          }
        }
      });*/
  }

  /**
   * Determines whether the user has entered the secret code.
   * @returns True if the user has entered the secret code, false otherwise.
   */
  private isSecretCode(): boolean {
    return this.kunamiCode.every(
      (code: string, index: number) => code === this.sequence[index],
    );
  }

  /**
   * Add dark mode class.
   */
  addDarkMode(): void {
    this.darkEnabled = !this.darkEnabled;
  }

  /**
   * Cleanup method.
   */
  ngOnDestroy(): void {
    this.user$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
    this.navigation.destroyRouterNavigation();
  }
}
