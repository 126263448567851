import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { BoardComponent } from './board/board/board.component';
import { ContainerComponent } from './container/container/container.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { EnvironmentName } from 'src/models';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NotificationToastsContainerComponent } from './navigation/notification-toasts-container/notification-toasts-container.component';
import { StationComponent } from './station/station/station.component';
import { TopNavComponent } from './navigation/top-nav/top-nav.component';
import { AccountSettingsComponent } from './settings/account-settings/account-settings.component';
import { CodeEditorModule } from '@ngstack/code-editor';
import { MenuDrawerComponent } from 'src/app/navigation/menu/menu-drawer/menu-drawer.component';
import { InfoDrawerComponent } from './shared/info-drawer/info-drawer.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    BoardComponent,
    StationComponent,
    ContainerComponent,
    AccountSettingsComponent,
    MatSidenavModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.name === EnvironmentName.Production,
    }), // TODO: refactor; AppModule should only bootstrap other app modules
    NgIdleKeepaliveModule.forRoot(),
    NotificationToastsContainerComponent,
    TopNavComponent,
    CodeEditorModule.forRoot(),
    MenuDrawerComponent,
    InfoDrawerComponent,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
