@if (flagManagementMembersUpdate) {
  <section class="mt-4 w-full h-[calc(100%-79px)]">
    <section class="flex justify-between h-11 mb-4">
      <div class="w-[calc(100%-50px)] pl-5">
        <h4 class="!font-semibold truncate">Manage Members</h4>
        <p class="line-clamp-[1]">{{ this.entityName }}</p>
      </div>

      <button
        class="mr-2"
        mat-icon-button
        type="button"
        [mat-dialog-close]="true"
      >
        <i class="fa-light fa-xmark text-lg"></i>
      </button>
    </section>
    <!-- Section for Table Members Modal. -->
    <app-table-members
      [showUserOrganization]="true"
      [rithmId]="rithmId"
      [entityType]="isFolder ? entityType.BoardFolder : entityType.Board"
      [showAllMembers]="true"
      [displayedColumns]="displayedColumns"
    />

    <section class="bottom-0 right-0 absolute pb-3 mr-4">
      <button mat-button type="button" [mat-dialog-close]="true">Close</button>
    </section>
  </section>
} @else {
  <div class="flex justify-between items-center px-5 pt-5">
    <h3 class="font-semibold w-10/12 truncate">
      <h3>Manage Members</h3>
    </h3>
    <button
      data-testid="close-manage-members-modal"
      id="close-manage-members-modal"
      mat-icon-button
      type="button"
      class="text-secondary-500"
      [mat-dialog-close]="true"
    >
      <i class="fas fa-times text-lg"></i>
    </button>
  </div>

  <section
    [ngClass]="{
      'h-full': (!isUpdating && isLoading) || errorGetUsersMember
    }"
  >
    <!--Loading -->
    @if (!isUpdating && isLoading) {
      <app-loading-widget id="app-loading-indicator-user-member" />
    }

    <!--Show error -->
    @if (errorGetUsersMember) {
      <app-error-widget
        id="error-load-user-member"
        [errorMessage]="'Unable to obtain members.'"
        [permission]="true"
        (tryAgain)="getUsersAndTeams()"
      />
    }
  </section>

  @if (isUpdating || (!isLoading && !errorGetUsersMember)) {
    <div
      class="h-[calc(100%-60px)] px-5"
      [ngClass]="{
        'disable-scroll-tab': isLoadingTabAddMember && indexTab === 3
      }"
    >
      <mat-tab-group
        mat-stretch-tabs="false"
        [(selectedIndex)]="indexTab"
        (selectedTabChange)="clearFilters()"
        class="active-mat-group h-full"
      >
        <mat-tab>
          <ng-template matTabLabel>
            <p class="font-semibold">ALL</p>
            <span
              class="bg-primary-100 border-none rounded mx-1 px-1 py-0.5 font-semibold"
              >{{ membersSpecific.teamMember.length }}</span
            >
          </ng-template>

          @if (indexTab !== 0) {
            <ng-container [ngTemplateOutlet]="loading" />
          } @else if (indexTab === 0) {
            <ng-container
              [ngTemplateOutlet]="listsMemberTeamTemplate"
              [ngTemplateOutletContext]="{
                $implicit: membersFiltered.teamMember
              }"
            />
          }
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <p class="font-semibold">MEMBERS</p>
            <span
              class="bg-primary-100 border-none rounded mx-1 px-1 py-0.5 font-semibold"
              >{{ membersSpecific.member.length }}</span
            >
          </ng-template>
          @if (indexTab !== 1) {
            <ng-container [ngTemplateOutlet]="loading" />
          } @else if (indexTab === 1) {
            <ng-container
              [ngTemplateOutlet]="listsMemberTeamTemplate"
              [ngTemplateOutletContext]="{ $implicit: membersFiltered.member }"
            />
          }
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <p class="font-semibold">TEAMS</p>
            <span
              class="bg-primary-100 border-none rounded mx-1 px-1 py-0.5 font-semibold"
              >{{ membersSpecific.team.length }}</span
            >
          </ng-template>
          @if (indexTab !== 2) {
            <ng-container [ngTemplateOutlet]="loading" />
          } @else if (indexTab === 2) {
            <ng-container
              [ngTemplateOutlet]="listsMemberTeamTemplate"
              [ngTemplateOutletContext]="{ $implicit: membersFiltered.team }"
            />
          }
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <p class="font-semibold">ADD MEMBERS</p>
          </ng-template>
          @if (!membersFiltered.addMembers.length && isLoading) {
            <ng-container [ngTemplateOutlet]="loading" />
          } @else if (!isLoading) {
            <ng-container
              [ngTemplateOutlet]="listsMemberTeamTemplate"
              [ngTemplateOutletContext]="{
                $implicit: membersFiltered.addMembers
              }"
            />
          }
          @if (membersFiltered.addMembers.length && !isLoading) {
            <div
              class="w-full flex justify-end actions"
              mat-dialog-actions
              align="end"
            >
              <button
                class="mt-4"
                id="save-button"
                data-testid="save-button"
                mat-flat-button
                type="button"
                [disabled]="disableSaveButton"
                color="accent"
                (click)="addPermissions()"
              >
                ADD
                {{
                  getUsersTeamsToUpdate('member').length +
                    getUsersTeamsToUpdate('team').length
                }}
                MEMBERS
              </button>
            </div>
          }
        </mat-tab>
      </mat-tab-group>
    </div>
  }
  <ng-template #listsMemberTeamTemplate let-listMember>
    <ng-container [ngTemplateOutlet]="headerFilterTemplate" />
    @if (isLoadingTabAddMember) {
      <ng-container [ngTemplateOutlet]="loading" />
    } @else {
      <div
        [formGroup]="form"
        class="overflow-auto scrollbar mt-1"
        [ngClass]="{
          'h-[calc(100%-130px)] sm:h-[calc(100%-135px)] md:h-[calc(100%-90px)] 2xl:h-[calc(100%-95px)]':
            indexTab !== 3,
          'h-[calc(100%-175px)] sm:h-[calc(100%-180px)] md:h-[calc(100%-135px)] 2xl:h-[calc(100%-140px)]':
            indexTab === 3
        }"
      >
        <div class="overflow-y-auto w-[575px] md:w-full">
          @if (!listMember.length) {
            <div class="w-full text-center mt-5" id="message-error-members">
              There are not member for show.
            </div>
          }
          @if (listMember.length) {
            <div
              class="flex justify-between gap-4 text-xs text-primary-300 pt-2 border-b border-secondary-200 mt-3 py-3"
            >
              <div class="w-[60%] text-xs">
                @if (indexTab === 3) {
                  <mat-checkbox
                    formControlName="canViewAll"
                    (change)="onChangeSelectAll()"
                    class="mx-3"
                  ></mat-checkbox>
                }
                {{
                  indexTab === 2 ? 'TEAM' : indexTab === 3 ? 'ADD' : 'MEMBER'
                }}
              </div>
              <span class="w-[40%]"
                >{{
                  isFolder
                    ? 'FOLDER'
                    : termsGeneric.Board.Title.Single.toUpperCase()
                }}
                ROLE</span
              >
            </div>
            @for (member of listMember; track i; let i = $index) {
              <app-member-board-list-modal
                [formControlName]="member.rithmId"
                [member]="member"
                [ownUserRithmId]="ownUserRithmId"
                [canViewAll]="canViewAll"
                [isAddMember]="indexTab === 3"
                [index]="i"
                [isFolder]="isFolder"
                (deselectCanViewAll)="deselectCanViewAll()"
                (updateMember)="
                  confirmActionDelete($event.member, $event.updateType)
                "
                [isLoading]="isLoading"
              />
            }
          }
        </div>
      </div>
    }
  </ng-template>

  <ng-template #headerFilterTemplate>
    <div class="md:flex md:justify-between mt-3">
      <div class="w-full md:w-2/4 input-slim-mdc">
        <mat-form-field appearance="outline">
          <input
            [(ngModel)]="search"
            (ngModelChange)="changeSearchAndFilterMenu()"
            type="text"
            matInput
            placeholder="Search"
            id="search-containers"
            data-testid="search-containers"
            maxlength="55"
            autocomplete="off"
          />
          <span matPrefix
            ><i class="fa-light fa-search text-secondary-300"></i
          ></span>
        </mat-form-field>
      </div>

      <div class="select-filter mt-2 md:mt-0">
        <button
          mat-button
          type="button"
          id="open-filter-menu"
          data-testid="open-filter-menu"
          [matMenuTriggerFor]="menuFilters"
          class="text-xs"
          #Menu="matMenuTrigger"
        >
          <span class="flex items-center p-2">
            {{ indexTab === 3 ? 'Display' : 'Role' }}: {{ selectedFilter }}

            <i
              class="fa-light text-secondary-400 text-lg ml-2"
              [ngClass]="Menu.menuOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
            ></i
          ></span>
        </button>

        <mat-menu #menuFilters="matMenu">
          <div class="menu-item-custom">
            @for (
              filter of indexTab === 3 ? displayMembers : roles;
              track $index
            ) {
              <button
                mat-menu-item
                type="button"
                [id]="'filter-menu-' + filter"
                [attr.data-testid]="'filter-menu-' + filter"
                (click)="changeSearchAndFilterMenu(filter)"
              >
                {{ filter }}
              </button>
            }
          </div>
        </mat-menu>
      </div>
    </div>
  </ng-template>

  <ng-template #loading>
    <!--Loading -->
    <app-loading-widget id="app-loading-indicator-user-member" />
  </ng-template>
}
